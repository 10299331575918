<template>
  <v-dialog
    v-model="isShowModal"
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('evoucher.issuance.extendExpiredTitle')"
        tile
        color="green"
        full-width>
        <v-container
          grid-list-xl
          fluid
          style="padding: 0;">
          <v-form
            ref="form"
            lazy-validation>
            <v-layout
              layout
              wrap>
              <v-flex
                xs12
                sm6
                md6>
                <v-select
                  v-model="externOptionSelected"
                  :items="externOptionList">
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <v-flex
                v-if="externOptionSelected==ExpirationType.DURATION"
                xs12
                sm6
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue, ruleMustGreaterThanZero]"
                  v-model="durationAdd"
                  :label="$t('evoucher.issuance.extendDuration')"
                  :name="$t('evoucher.duration')"
                  type="number"
                  class="required"
                />
              </v-flex>
              <v-flex
                v-if="externOptionSelected==ExpirationType.EXPIRY_DATE"
                xs12
                sm6
                md6>
                <v-menu
                  ref="menuEndDate"
                  v-model="menuExpiredDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  lazy>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :rules="[ruleRequiredValue]"
                      v-model="expiredDateFormatted"
                      :label="$t('evoucher.issuance.extendToDate')"
                      persistent-hint
                      class="required"
                      @blur="date = parseDate(expiredDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="expiredDate"
                    :min="minExpiredDate"
                    no-title
                    @input="menuExpiredDate = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm12
                md12>
                <i>
                  <span v-if="externOptionSelected==ExpirationType.DURATION">{{ $t('evoucher.issuance.extendDurationDescription', { 'days': durationAdd }) }}</span>
                  <span v-if="externOptionSelected==ExpirationType.EXPIRY_DATE">{{ $t('evoucher.issuance.extendToDateDescription') }}</span>
                </i>
              </v-flex>
            </v-layout>
            <v-layout
              v-if="voucherIds==null"
              layout
              wrap>
              <v-flex
                xs12
                sm12
                md12>
                <div>{{ $t('evoucher.voucherName') }}: <b>{{ voucherName }}</b></div>
                <div v-if="workpieceId">{{ $t('evoucher.issuance.workpiece') }}: <b>{{ issuanceCode }}</b></div>
                <div>{{ $t('evoucher.issuance.numOfVoucherDistributedToCustomer') }}: <b>{{ numVouchersDistributed }}</b></div>
                <div>{{ $t('evoucher.issuance.numOfVoucherDistributedHasExpired') }}: <b>{{ numVouchersExpired }}</b></div>
              </v-flex>
            </v-layout>
            <v-layout
              v-else
              layout
              wrap>
              <v-flex
                xs12
                sm12
                md12>
                <v-data-table
                  ref="tblVoucherList"
                  :no-data-text="$t('common.noDataAvailable')"
                  :headers="voucherInfoHeaders"
                  :items="voucherInfoList"
                  :single-select="true"
                  hide-actions>
                  <template
                    slot="headerCell"
                    slot-scope="{ header }">
                    <span
                      v-if="header.value"
                      class="text--darken-3 font-medium"
                      v-text="$t(header.text)"
                    />
                  </template>
                  <template v-slot:items="props">
                    <tr
                      :class="props.selected ? 'blue--text' : ''"
                      style="position: relative;">
                      <td
                        v-for="(header, index) in voucherInfoHeaders"
                        :key="header.value + index">
                        <span class="mt-1">{{ props.item[header.value] }}</span>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
            <pagination
              :total="voucherPaginate.totalPage"
              :current-page="voucherPaginate.currentPage"
              :row-per-page="voucherPaginate.rowPerPage"
              @onPageChange="onPageChange"
            />
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="isShowModal = false"
        >{{ $t('common.close') }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm($event)"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import dateUtils from 'utils/dateUtils'
import { DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS, DEFAULT_MINIMUM_DAYS_FOR_EXPIRED_USING } from 'utils/constants'
import ToastType from 'enum/toastType'
import ExpirationType from 'enum/expirationType'

export default {
  props: {
    title: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data () {
    return {
      isShowModal: false,
      issuanceId: null,
      workpieceId: null,
      voucherName: null,
      issuanceCode: null,
      numVouchersDistributed: 0,
      numVouchersExpired: 0,
      voucherIds: null,
      ExpirationType: ExpirationType,
      externOptionSelected: ExpirationType.DURATION,
      externOptionList: [
        { text: 'evoucher.issuance.extendDuration', value: ExpirationType.DURATION },
        { text: 'evoucher.issuance.extendToDate', value: ExpirationType.EXPIRY_DATE }
      ],
      durationAdd: DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS,
      menuExpiredDate: false,
      expiredDateFormatted: moment().add(DEFAULT_MINIMUM_DAYS_FOR_EXPIRED_USING, 'days').format('DD/MM/YYYY'),
      expiredDate: moment().add(DEFAULT_MINIMUM_DAYS_FOR_EXPIRED_USING, 'days').format('YYYY-MM-DD'),
      minExpiredDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      voucherInfoHeaders: [
        { text: 'common.nonumber', value: 'stt' },
        { text: 'evoucher.voucherSerial', value: 'serial' },
        { text: 'evoucher.voucherName', value: 'name' },
        { text: 'customer.nameCustomer', value: 'customerName' },
        { text: 'customer.emailCustomer', value: 'customerEmail' },
        { text: 'createVoucher.endDateOfUse', value: 'expiredDate' }
      ],
      voucherInfoList: [],
      voucherPaginate: {
        totalPage: 1,
        currentPage: 1,
        rowPerPage: 1
      }
    }
  },
  watch: {
    expiredDate (val) {
      this.expiredDateFormatted = this.formatDate(this.expiredDate)
    }
  },
  methods: {
    doExtendVoucherUsingExpiration: function () {
      let expiredDate = (this.externOptionSelected === ExpirationType.EXPIRY_DATE) ? this.expiredDateFormatted : null
      let duration = (this.externOptionSelected === ExpirationType.DURATION) ? this.durationAdd : null
      if (this.voucherIds === null) {
        let filter = {
          issuanceId: this.issuanceId,
          workpieceId: this.workpieceId,
          expiredDate: expiredDate,
          duration: duration
        }
        return this.EXTEND_VOUCHER_USING_EXPIRATION_BY_ISSUANCE(filter)
      } else {
        let filter = {
          voucherIds: this.voucherIds,
          expiredDate: expiredDate,
          duration: duration
        }
        return this.EXTEND_VOUCHER_USING_EXPIRATION(filter)
      }
    },
    onConfirm: function (event) {
      event.preventDefault()
      if (this.$refs.form.validate()) {
        this.doExtendVoucherUsingExpiration().then(
          function (res) {
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.isShowModal = false
            this.$emit('onUpdated')
          }.bind(this)
        ).catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styleType: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    onCloseModal: function () {
      this.isShowModal = false
    },
    onShowModal: function (voucherIds, workpieceId, issuanceId) {
      this.isShowModal = true
      this.voucherIds = voucherIds
      this.issuanceId = issuanceId
      this.workpieceId = workpieceId
      if (voucherIds !== null) {
        this.getListVoucherUsingExpiration()
      } else {
        this.getVoucherUsingExpirationByIssuance()
      }
    },
    onPageChange: function (page) {
      this.voucherPaginate.currentPage = page
      this.getListVoucherUsingExpiration()
    },
    getListVoucherUsingExpiration: function () {
      let filter = {
        params: {
          page: this.voucherPaginate.currentPage,
          voucherIds: JSON.stringify(this.voucherIds)
        }
      }
      // this.isLoading = true
      this.GET_VOUCHERS_USING_EXPIRATION(filter).then(
        function (res) {
          let data = res.data
          // Handle paginate
          this.voucherPaginate.currentPage = data.page
          this.voucherPaginate.totalPage = data.num_pages
          this.voucherPaginate.rowPerPage = data.per_page / 2
          // Handle response data
          this.voucherInfoList = []
          let vouchers = data.results
          for (let i = 0, size = vouchers.length; i < size; i++) {
            let voucherObj = {
              stt: i + 1 + (data.page - 1) * data.page_size,
              id: vouchers[i].id,
              serial: vouchers[i].serial,
              name: vouchers[i].name,
              status: vouchers[i].status,
              expiredDate: dateUtils.formatBeautyDate(vouchers[i].expired_date, null),
              customerName: functionUtils.concatFullname(vouchers[i].customer.last_name, vouchers[i].customer.first_name),
              customerEmail: vouchers[i].customer.email
            }
            this.voucherInfoList.push(voucherObj)
          }
          // this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          // this.isLoading = false
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    getVoucherUsingExpirationByIssuance: function () {
      let filter = {
        params: {
          issuanceId: this.issuanceId,
          workpieceId: this.workpieceId
        }
      }
      // this.isLoading = true
      this.GET_VOUCHERS_USING_EXPIRATION_BY_ISSUANCE(filter).then(
        function (res) {
          let data = res.data
          this.voucherName = data.name
          this.numVouchersDistributed = data.total_voucher_distributed
          this.numVouchersExpired = data.total_voucher_expired
          this.issuanceCode = data.issuance
          // this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          // this.isLoading = false
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleMustGreaterThanZero (value) {
      return validationRules.ruleMustGreaterThan(value, 0)
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_VOUCHERS_USING_EXPIRATION',
      'GET_VOUCHERS_USING_EXPIRATION_BY_ISSUANCE',
      'EXTEND_VOUCHER_USING_EXPIRATION',
      'EXTEND_VOUCHER_USING_EXPIRATION_BY_ISSUANCE'
    ])
  }
}

</script>

<style lang="scss" scoped>
  .modal {
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .v-card {
    margin-bottom: 0px !important;
    margin-top: 21px !important;
  }
</style>
