<template>
  <v-dialog
    v-model="isShowModal"
    :max-width="MODAL_WIDTH"
    persistent>
    <v-card>
      <material-card
        :title="$t('evoucher.voucherDistribution')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <!-- Download template voucher -->
          <a
            type="button"
            class="v-btn theme--dark warning"
            download
            @click="onDownloadTemplate">
            <v-icon
              class="mr-2"
              color="white">mdi-download</v-icon> <span style="text-transform: none;">{{ $t('common.downloadTemplate') }}</span>
          </a>
          <div
            class="float-right mr-2 pb-4 mt-2"
          >
            <upload-excel
              :on-success="handleSuccess"
              :before-upload="beforeUpload"/>
            <div class="mt-4">
              <span>{{ $t('distributeExcelMode.preview') }}</span>
              <div style="max-height: 400px; overflow-y: auto;">
                <table
                  class="mt-2"
                  style="min-width:100%;">
                  <tr>
                    <th
                      v-for="(header, index) in excelListVoucherIssue.headers"
                      v-show="!forConasi || (header != constHeader.sms_en && header != constHeader.sms_vi)"
                      :key="header + index"
                      class="text-xs-center">
                      <span
                        v-if="header == constHeader.sms_en || header == constHeader.sms_vi"
                        v-show="!forConasi">
                        {{ header }}
                      </span>
                      <span v-else>{{ header }}</span>
                    </th>
                  </tr>
                  <tr
                    v-for="(result, index) in excelListVoucherIssue.results"
                    :key="result + index"
                    :style="(isFailedValidation(result[constHeader.status_en]) && isFailedValidation(result[constHeader.status_vi])) ? '' : 'color: red;'"
                  >
                    <td
                      v-for="(header, index) in excelListVoucherIssue.headers"
                      v-show="!forConasi || (header != constHeader.sms_en && header != constHeader.sms_vi)"
                      :key="header + index"
                      nowrap>
                      <div
                        v-if="header == constHeader.status_en || header == constHeader.status_vi"
                        :style="isFailedValidation(result[header]) ? 'color: #66bb6a;' : 'color: red;'">
                        {{ result[header] }}
                      </div>
                      <div
                        v-else-if="header == constHeader.code_en || header == constHeader.code_vi">
                        {{ (result[header] && result[header].length) > 0 ? String(result[header]).replace('[', "") : '' }}
                      </div>
                      <div
                        v-else-if="header == constHeader.sms_en || header == constHeader.sms_vi"
                        v-show="!forConasi">
                        {{ getSmsState(result[header]) }}
                      </div>
                      <div
                        v-else-if="header == constHeader.detail_en || header == constHeader.detail_vi"
                        style="white-space: pre;">
                        {{ result[header] }}
                      </div>
                      <div
                        v-else-if="header == constHeader.quantity_en || header == constHeader.quantity_vi"
                        class="text-xs-center"
                      >
                        {{ result[header] }}
                      </div>
                      <div v-else>
                        {{ result[header] }}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="mt-2">
                <div v-if="excelListVoucherIssue.countErrorProcessing > 0 && excelListVoucherIssue.countSuccessedProcessing > 0">
                  <span
                    style="display: block;"
                    class="font-italic font-weight-black">{{ $t('distributeExcelMode.buggyReleaseYouWantedContinue') }}</span>
                  <span
                    class="font-italic font-weight-bold"
                    style="color: red;">{{ $t('distributeExcelMode.notice') }}: </span> <span class="font-italic font-weight-black">{{ $t('distributeExcelMode.noticeReleaseContinue') }}</span>
                </div>
                <div v-if="excelListVoucherIssue.countEmailExistProcessing > 0 && excelListVoucherIssue.countSuccessedProcessing > 0">
                  <span style="display: block">
                    <span
                      class="font-italic font-weight-bold"
                      style="color: red;">{{ $t('distributeExcelMode.notice') }}: </span>
                    <span
                      v-if="excelListVoucherIssue.countEmailExistProcessing > 0"
                      class="font-italic font-weight-black d-block">
                      - {{ $t('distributeExcelMode.willIgnoreInfo') }} <span
                        class="font-weight-bold"
                        style="color: red;"> {{ excelListVoucherIssue.countEmailExistProcessing }} </span> {{ $t('distributeExcelMode.becauseAlreadyExistInSystem') }}
                    </span>
                    <span
                      v-if="excelListVoucherIssue.countQuantitySmsUnavailable > 0"
                      class="font-italic font-weight-black d-block">
                      - {{ $t('distributeExcelMode.becauseNumberSMSHasExceededAllowedNumber') }}
                    </span>
                  </span>
                </div>
                <v-btn
                  v-if="isReadyToDeliver()"
                  :loading="isLoading"
                  style="display: block; margin-left: 0; margin-right: 0;"
                  small
                  color="success darken-1"
                  @click="onConfirm(isForceDistribute = true)"
                >{{ $t('common.continue') }}
                </v-btn>
              </div>
            </div>
            <div
              v-if="!forConasi && (emailTemplateList.length > 0)"
              class="mt-4"
              style="max-width:300px;"
            >
              <v-autocomplete
                v-model="emailTemplateId"
                :items="emailTemplateList"
                :clearable="true"
                :label="$t('templateEmail.chooseTemplate')"
              />
            </div>
          </div>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          v-if="!hideBtnConfirm"
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm(isForceDistribute = false)"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import UploadExcel from 'Components/UploadExcel'
import { mapActions, mapGetters } from 'vuex'
import stringUtils from 'utils/stringUtils'
import functionUtils from 'utils/functionUtils'
import { FOR_CONASI_TARGET } from 'utils/constants'
import EmailTemplateCatelogType from 'enum/emailTemplateCatelogType'
import EntityType from 'enum/entityType'
import XLSX from 'xlsx'
export default {
  components: {
    UploadExcel
  },
  data () {
    return {
      constHeader: {
        code_vi: 'Mã phát hành',
        code_en: 'Issuance code',
        sms_vi: 'Gởi SMS',
        sms_en: 'Send SMS',
        quantity_vi: 'Số lượng',
        quantity_en: 'Quantity',
        status_vi: 'Trạng thái',
        status_en: 'Status',
        detail_vi: 'Chi tiết',
        detail_en: 'Detail',
        result: 'Thành công'
      },
      excelListVoucherIssue: {
        results: [],
        headers: [],
        countErrorProcessing: 0,
        countSuccessedProcessing: 0,
        countEmailExistProcessing: 0,
        countQuantitySmsUnavailable: 0
      },
      domainPath: stringUtils.DOMAIN_BASE_PATH_ADMIN,
      MODAL_WIDTH: '1200px',
      isLoading: false,
      isShowModal: false,
      isForceDistribute: false,
      hideBtnConfirm: false,
      emailTemplateList: [],
      emailTemplateId: null,
      forConasi: FOR_CONASI_TARGET
    }
  },
  computed: {
    ...mapGetters([
      'TEMPLATE_EMAIL_DATA'
    ])
  },
  watch: {
    TEMPLATE_EMAIL_DATA () {
      let templateEmailData = this.TEMPLATE_EMAIL_DATA.results
      if (templateEmailData.length > 0) {
        this.emailTemplateList = [
          {
            value: null,
            text: '-- ' + this.$t('templateEmail.usedDefault') + ' --',
            usedDefault: false
          }
        ]
        for (let i = 0, size = templateEmailData.length; i < size; i++) {
          let obj = {
            value: templateEmailData[i].id,
            text: templateEmailData[i].name,
            usedDefault: templateEmailData[i].used_default
          }
          this.emailTemplateList.push(obj)
        }
      } else {
        this.emailTemplateList = []
      }
    }
  },
  methods: {
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    /**
     * Confirm deliver voucher to customer by excel mode
     */
    onConfirm () {
      if (!this.isForceDistribute) {
        this.excelListVoucherIssue.countErrorProcessing = 0
        this.excelListVoucherIssue.countSuccessedProcessing = 0
        this.excelListVoucherIssue.countEmailExistProcessing = 0
        this.excelListVoucherIssue.countQuantitySmsUnavailable = 0
      }
      let filter = {
        headers: this.excelListVoucherIssue.headers,
        dataImport: this.excelListVoucherIssue.results,
        isForceDistribute: this.isForceDistribute,
        customerUrl: stringUtils.CUSTOMER_URL,
        emailTemplateId: this.emailTemplateId
      }

      this.isLoading = true
      this.DISTRIBUTE_VOUCHER_TO_CUSTOMER_FROM_EXCEL(filter).then(
        function (res) {
          let data = res.data
          if (this.isForceDistribute) {
            this.isForceDistribute = false
            this.excelListVoucherIssue.countErrorProcessing = 0
            this.excelListVoucherIssue.countSuccessedProcessing = 0
            this.excelListVoucherIssue.countEmailExistProcessing = 0
            this.excelListVoucherIssue.countQuantitySmsUnavailable = 0
          } else {
            this.excelListVoucherIssue.countErrorProcessing = data.error_processing_count
            this.excelListVoucherIssue.countSuccessedProcessing = data.successed_processing_count
            this.excelListVoucherIssue.countEmailExistProcessing = data.email_exist_processing_count
            this.excelListVoucherIssue.countQuantitySmsUnavailable = data.quantity_sms_unavailable_count
          }
          this.isLoading = false
          this.excelListVoucherIssue.headers = data.headers
          this.excelListVoucherIssue.results = data.response_list
          this.hideBtnConfirm = true
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Handle success upload file excel
     */
    handleSuccess: function ({ results, header }) {
      this.excelListVoucherIssue.results = results
      this.excelListVoucherIssue.headers = header
      this.excelListVoucherIssue.countErrorProcessing = 0
      this.excelListVoucherIssue.countSuccessedProcessing = 0
      this.excelListVoucherIssue.countEmailExistProcessing = 0
      this.excelListVoucherIssue.countQuantitySmsUnavailable = 0
      this.hideBtnConfirm = false
    },
    /**
     * Before upload file excel
     */
    beforeUpload: function (file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      return false
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.isLoading = false
      this.isShowModal = false
      this.excelListVoucherIssue.results = []
      this.excelListVoucherIssue.headers = []
      this.excelListVoucherIssue.countErrorProcessing = 0
      this.excelListVoucherIssue.countSuccessedProcessing = 0
      this.excelListVoucherIssue.countEmailExistProcessing = 0
      this.excelListVoucherIssue.countQuantitySmsUnavailable = 0
      this.hideBtnConfirm = false
      this.$emit('transactionVoucherSuccess')
    },
    /**
     * Show modal
     */
    onShowModal: function () {
      this.isShowModal = true
      this.getTemplateEmailList()
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.setDefaultData()
    },
    getSmsState: function (text) {
      if (functionUtils.isStringTrue(text)) {
        return this.$t('distributeExcelMode.usingSMS')
      }
      return text
    },
    isFailedValidation: function (statusText) {
      if (functionUtils.isEmptyString(statusText) || statusText === this.constHeader.result) {
        return true
      }
      return false
    },
    isReadyToDeliver: function () {
      return (this.excelListVoucherIssue.countErrorProcessing > 0 || this.excelListVoucherIssue.countEmailExistProcessing > 0) && this.excelListVoucherIssue.countSuccessedProcessing > 0
    },
    getTemplateEmailList: function () {
      if (!this.isSupplier()) {
        this.emailTemplateList = []
      }
      let filter = {
        params: {
          categoryId: EmailTemplateCatelogType.RELEASE_TO_CUSTOMER
        }
      }
      this.emailTemplateId = null
      this.isLoading = true
      this.GET_TEMPLATE_EMAIL(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    onDownloadTemplate: function () {
      // let allowSMSDelivery = functionUtils.hasEntityRoles('allowSMSDelivery')
      let lang = this.$i18n.locale
      let issuanceData = {}
      let exportData = {}
      let sendSmsData = {}
      if (lang === 'vi') {
        if (this.forConasi) {
          issuanceData = { 'Mã phôi': '0A1B2C3D4E' }
        } else {
          issuanceData = { 'Mã phát hành': '0A1B2C3D4E' }
        }
        exportData = {
          'Số lượng': 1,
          'Họ Tên Khách Hàng': 'Nguyen Van A',
          'Email khách hàng': 'example@gmail.com',
          'Số điện thoại': '0987654321',
          'Giới tính': 'Nam / Nữ',
          'Ghi chú': 'Ghi chú ngắn (tối đa 150 ký tự)'
        }
        if (!this.forConasi) {
          sendSmsData = { 'Gởi SMS': 'Yes / No' }
        }
      } else {
        if (this.forConasi) {
          issuanceData = { 'Workpiece code': '0A1B2C3D4E' }
        } else {
          issuanceData = { 'Issuance Code': '0A1B2C3D4E' }
        }
        exportData = {
          'Quantity': 1,
          'Customer Name': 'Nguyen Van A',
          'Customer Email': 'example@gmail.com',
          'Phone Number': '0987654321',
          'Gender': 'Male / Female',
          'Note': 'Short note (max 150 chars)'
        }
        if (!this.forConasi) {
          sendSmsData = { 'Send SMS': 'Yes / No' }
        }
      }
      let dataList = [{
        ...issuanceData,
        ...exportData,
        ...sendSmsData
      }]
      // export json to Worksheet of Excel
      // only array possible
      var dataSheet = XLSX.utils.json_to_sheet(dataList)

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, dataSheet, 'DeliveryList') // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, 'distribute_voucher_to_customer.xlsx') // name of the file is 'book.xlsx'
    },
    ...mapActions([
      'DISTRIBUTE_VOUCHER_TO_CUSTOMER_FROM_EXCEL',
      'GET_TEMPLATE_EMAIL'
    ])
  }
}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}
</style>
