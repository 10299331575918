<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="1000px">
    <v-card ref="card">
      <material-card
        :title="forConasi ? $t('workpiece.addNew') : $t('evoucher.addNewVoucher')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              wrap
              layout>
              <v-flex
                v-if="(workpiece.type != VoucherType.PROMOTION)"
                style="padding-top: 0px !important; padding-bottom: 0px;"
                xs12
                sm6
                md6>
                <v-checkbox
                  v-model="workpiece.isTransferable"
                  :label="$t('evoucher.issuance.transferable')"
                  style="margin: 0; padding: 0;"
                />
              </v-flex>
              <v-flex
                v-if="(workpiece.type === VoucherType.VOUCHER)"
                style="padding-top: 0px !important; padding-bottom: 0px;"
                xs12
                sm6
                md6>
                <v-checkbox
                  v-model="workpiece.isHidePrice"
                  :label="$t('workpiece.hidePrice')"
                  style="margin: 0; padding: 0;"
                />
              </v-flex>
              <v-flex
                v-if="(workpiece.type != VoucherType.PROMOTION)"
                style="padding-top: 0px !important; padding-bottom: 0px;"
                xs12
                sm12
                md12>
                <v-checkbox
                  v-model="workpiece.isPersistentCode"
                  :label="$t('workpiece.persistentActiveCode')"
                  style="margin: 0; padding: 0;"
                />
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              layout>
              <!-- Name -->
              <v-flex
                xs12
                sm3
                md3>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="workpiece.name"
                  :label="$t('actions.name')"
                  :name="$t('actions.name')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <!-- Quantity -->
              <v-flex
                xs12
                sm3
                md3>
                <v-text-field
                  :rules="[ruleRequiredValue, ruleMustGreaterThanZero]"
                  v-model="workpiece.quantity"
                  :label="$t('evoucher.issuance.quantity')"
                  :name="$t('evoucher.issuance.quantity')"
                  :disabled="(workpiece.type == VoucherType.PROMOTION && promotionTypeSelected == promotionType.ALLOCATED)"
                  type="number"
                  class="required"
                />
              </v-flex>
              <!-- Model list -->
              <!-- Type -->
              <v-flex
                v-if="forConasi"
                xs12
                sm3
                md3>
                <v-select
                  v-model="workpiece.type"
                  :items="workpiece.typeList"
                  :label="$t('evoucher.issuance.type')"
                  @change="voucherIssuanceTypeChange"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <v-flex
                v-else
                xs12
                sm3
                md3>
                <v-select
                  v-model="workpiece.type"
                  :items="workpiece.valueTypeList"
                  :label="$t('evoucher.issuance.type')"
                  @change="voucherIssuanceTypeChange"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <!-- Value -->
              <v-flex
                xs12
                sm3
                md3>
                <v-text-field
                  :rules="[ruleRequiredValue, ruleMustGreaterThanZero, ruleMaxPercentValue]"
                  v-model="workpiece.valueFormatter"
                  :label="(workpiece.type == VoucherType.COUPON) ? $t('evoucher.issuance.percent') : $t('evoucher.issuance.price')"
                  :name="(workpiece.type == VoucherType.COUPON) ? $t('evoucher.issuance.percent') : $t('evoucher.issuance.price')"
                  :max="workpiece.type == VoucherType.COUPON ? 100 : false"
                  :type="workpiece.type == VoucherType.COUPON ? 'number' : 'text'"
                  class="required"
                  @input="formatValue"
                />
              </v-flex>
            </v-layout>
            <!-- Only apply when publishing voucher right after created workpiece -->
            <v-layout
              v-if="workpiece.type == VoucherType.PROMOTION"
              wrap
              layout>
              <!-- Promotion Type -->
              <v-flex
                v-if="workpiece.type == VoucherType.PROMOTION"
                xs12
                sm3
                md3>
                <v-select
                  :rules="[ruleRequiredValue]"
                  v-model="promotionTypeSelected"
                  :items="promotionTypeList"
                  :label="$t('promotionVoucher.type')"
                  class="required"
                  @change="voucherIssuanceTypeChange"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <v-flex
                xs12
                sm3
                md3
              >
                <v-text-field
                  v-if="workpiece.type == VoucherType.PROMOTION"
                  :rules="[ruleRequiredValue, ruleMustGreaterThanZero]"
                  v-model="workpiece.numOfUsesPerPerson"
                  :label="$t('evoucher.issuance.numOfUsesPerPerson')"
                  :name="$t('evoucher.issuance.numOfUsesPerPerson')"
                  type="number"
                  class="required"
                  @input="onVoucherPromotionTypeChange"
                />
              </v-flex>
              <!-- From -->
              <v-flex
                v-if="workpiece.type == VoucherType.PROMOTION"
                xs12
                sm3
                md3>
                <v-menu
                  ref="menuEndDate"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  lazy
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :rules="[ruleRequiredValue]"
                      v-model="startDateFormatted"
                      :label="$t('createVoucher.fromDate')"
                      persistent-hint
                      class="required"
                      @blur="date = parseDate(startDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    :min="minStartDate"
                    no-title
                    @input="menuStartDate = false"
                  />
                </v-menu>
              </v-flex>
              <!-- To -->
              <v-flex
                v-if="workpiece.type == VoucherType.PROMOTION"
                xs12
                sm3
                md3>
                <v-menu
                  ref="menuEndDate"
                  v-model="menuExpiredDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  lazy
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :rules="[ruleRequiredValue]"
                      v-model="expiredDateFormatted"
                      :label="$t('createVoucher.toDate')"
                      persistent-hint
                      class="required"
                      @blur="
                        date = parseDate(expiredDateFormatted)
                      "
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="expiredDate"
                    :min="minExpiredDate"
                    no-title
                    @input="menuExpiredDate = false"
                  />
                </v-menu>
              </v-flex>
              <!-- Number of uses per person -->
              <v-flex
                v-if="(workpiece.type == VoucherType.COUPON) && !forConasi"
                xs12
                sm12
                md12>
                <span><span style="color: red; font-weight: bold;">* </span>{{ $t('evoucher.issuance.usedValidWithTypePercent') }}</span>
              </v-flex>
              <!-- Min Value -->
              <v-flex
                v-if="(workpiece.type == VoucherType.COUPON) && !forConasi"
                xs12
                sm4
                md4>
                <v-text-field
                  :rules="[ruleRequiredValue, ruleMustGreaterThanZero]"
                  v-model="workpiece.minValueFormatter"
                  :label="$t('evoucher.issuance.fromValue')"
                  :name="$t('evoucher.issuance.fromValue')"
                  class="required"
                  @input="formatMinValue"
                />
              </v-flex>
              <!-- Max Value -->
              <v-flex
                v-if="(workpiece.type == VoucherType.COUPON) && !forConasi"
                xs12
                sm4
                md4>
                <v-text-field
                  :rules="[ruleRequiredValue, ruleMustGreaterThanMinValue]"
                  v-model="workpiece.maxValueFormatter"
                  :label="$t('evoucher.issuance.toValue')"
                  :name="$t('evoucher.issuance.toValue')"
                  class="required"
                  @input="formatMaxValue"
                />
              </v-flex>
            </v-layout>
            <!-- Only apply when publishing voucher right after created workpiece -->
            <v-layout
              v-if="!forConasi && workpiece.type != VoucherType.PROMOTION"
              wrap
              layout>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-select
                  v-model="selectedExpirationType"
                  :items="expiredDateSelectItem"
                  :placeholder="$t('createVoucher.expiredDateOfCustomers')"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-if="selectedExpirationType === ExpirationType.DURATION"
                  :rules="[ruleRequiredValue, ruleMustGreaterThanZero]"
                  v-model="usageDuration"
                  :label="$t('evoucher.duration') + ' ' + $t('createVoucher.afterDistributedCustomers') + ` (` + $t('evoucher.issuance.day') + `)`"
                  :name="$t('evoucher.duration') + ` (` + $t('evoucher.issuance.day') + `)`"
                  type="number"
                  class="required"
                />
                <v-menu
                  v-if="selectedExpirationType === ExpirationType.EXPIRY_DATE"
                  ref="menuEndDate"
                  v-model="menuValidEndDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  lazy
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="usageEndDateFormatted"
                      :label="$t('createVoucher.toDate')"
                      :clearable="true"
                      persistent-hint
                      @blur="
                        date = parseDate(usageEndDateFormatted)
                      "
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="usageEndDate"
                    :min="minIssueStartDate"
                    no-title
                    @input="menuValidEndDate = false"
                  />
                </v-menu>
              </v-flex>
            </v-layout>
            <!--  -->
            <v-layout
              wrap
              layout>
              <!-- Select hashtag for voucher promotion allocated -->
              <v-flex
                v-if="workpiece.type == VoucherType.PROMOTION && promotionTypeSelected == promotionType.ALLOCATED"
                xs12
                sm12
                md12>
                <v-autocomplete
                  :rules="[ruleRequiredValue]"
                  v-model="hashtagSelecteds"
                  :items="hashtagList"
                  :clearable="true"
                  :label="$t('hashtag.customerGroupsAreReceived')"
                  multiple
                  class="required"
                  @change="onVoucherPromotionTypeChange"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ $t(item.text) }}</span>
                    </v-chip>
                    <span
                      v-if="index === 4"
                      class="grey--text caption">(+{{ hashtagSelecteds.length - 4 }} {{ $t('voucherReport.others') }})</span>
                  </template>
                  <template v-slot:item="{ item }">
                    {{ $t(item.text) }} [{{ item.count }}]
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              layout>
              <!-- Description -->
              <v-flex
                xs12
                sm6
                md6>
                <v-textarea
                  v-model="workpiece.description"
                  :label="$t('evoucher.issuance.voucherDescription')"
                  :name="$t('evoucher.issuance.voucherDescription')"
                  type="text"
                  rows="4"
                />
              </v-flex>
              <!-- Conditions of use -->
              <v-flex
                xs12
                sm6
                md6>
                <v-textarea
                  v-model="workpiece.conditionsOfUse"
                  :label="$t('evoucher.issuance.conditionsOfUse')"
                  :name="$t('evoucher.issuance.conditionsOfUse')"
                  type="text"
                  rows="4"
                />
              </v-flex>
              <!-- Details info for printing -->
              <v-flex
                v-if="forConasi && (workpiece.type != VoucherType.PROMOTION)"
                xs12
                sm12
                md12>
                <v-textarea
                  v-model="workpiece.details"
                  :label="$t('evoucher.issuance.details')"
                  :name="$t('evoucher.issuance.details')"
                  type="text"
                  rows="5"
                />
              </v-flex>
              <v-flex
                xs12
                sm12
                md12>
                <!-- TODO: have to double-check -->
                <v-radio-group
                  v-if="(false && !forConasi)"
                  v-model="selectSiteType"
                  row>
                  <v-radio
                    :value="false"
                    :label="$t('siteManagement.chooseSite')" />
                  <v-radio
                    :value="true"
                    :label="$t('groupSites.title')" />
                </v-radio-group>
                <v-autocomplete
                  v-if="selectSiteType"
                  :rules="[ruleRequiredValue]"
                  v-model="groupSiteIds"
                  :items="groupSiteList"
                  :clearable="true"
                  :label="$t('groupSites.title')"
                  multiple
                  class="required"
                >
                  <template v-slot:prepend-item>
                    <v-list-tile
                      ripple
                      @click="toggleAllEntitySite"
                    >
                      <v-list-tile-action>
                        <v-icon :color="groupSiteIds.length > 0 ? 'primary darken-4' : ''">
                          {{ allEntitySiteCheckIcon }}
                        </v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ $t('common.selectAll') }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider class="mt-2"/>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ $t(item.text) }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-else
                  v-model="usage_site.ids"
                  :items="usage_site.list"
                  :clearable="true"
                  :label="$t('siteManagement.chooseSite')"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-tile
                      ripple
                      @click="toggleAllSites"
                    >
                      <v-list-tile-action>
                        <v-icon :color="usage_site.ids.length > 0 ? 'primary darken-4' : ''">
                          {{ allSiteCheckIcon }}
                        </v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ $t('common.selectAll') }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider class="mt-2"/>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 4">
                      <span>{{ $t(item.text) }}</span>
                    </v-chip>
                    <span
                      v-if="index === 4"
                      class="grey--text caption">(+{{ usage_site.ids.length - 4 }} {{ $t('voucherReport.others') }})</span>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              layout>
              <!-- Issuance img -->
              <v-flex
                class="text-center"
                xs12
                md6
              >
                <image-picker
                  key="issuance-img"
                  :image-width="300"
                  :image-height="200"
                  :image-url="issuanceImage"
                  exceptation-ratio="3:2"
                  max-size-upload="3MB"
                  recommend-dimension="300x200"
                  @onSelectedImageFile="onSelectIssuanceImg"
                  @onResetImage="onClickRemoveIssuanceImg"
                />
              </v-flex>
              <!-- Album image for voucher product -->
              <v-flex
                v-if="forConasi"
                xs12
                md6
              >
                <div class="text-left pb-2 font-weight-bold font-small uppercase">
                  {{ $t('evoucher.issuance.productAlbum') }}
                </div>
                <div
                  class="d-flex"
                  style="width: fit-content; flex-flow: row wrap;">
                  <div
                    v-for="(data, index) in rawData"
                    :key="data + index"
                    class="image-input image-input-active d-flex"
                    style="width: fit-content;">
                    <div
                      class="image-preview"
                      style="border: 1px solid #e5e5e5;">
                      <img
                        :src="data"
                        class="img-responsive h-100">
                      <button
                        class="btn btn-xs remove-file"
                        @click="removeFile(index)">
                        <i class="fa fa-trash " />
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="files.length < option.maxFileCount"
                    class="image-input image-input-tbd">
                    <div
                      style="position: relative;"
                      class="image-preview dropzone d-flex justify-content-center align-items-center"
                      @drop="loaddropfile"
                      @click="openinput">
                      <i
                        class="fa fa-plus text-success"
                        style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);"/>
                    </div>
                    <input
                      id="upload-product-album-img"
                      type="file"
                      class="d-none"
                      @change="addImage">
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <v-layout
              v-if="forConasi && (workpiece.type != VoucherType.PROMOTION)"
              wrap
              layout>
              <v-flex
                xs12
                md6
              >
                <v-autocomplete
                  v-model="workpiece.printTemplateId"
                  :items="printTemplateList"
                  :clearable="true"
                  :label="$t('printVoucher.selectPrintTemplate')"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip>
                      <span>{{ $t(item.text) }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          v-if="forConasi"
          small
          color="warning darken-1"
          @click="onPreviewPrintVoucher"
        >{{ $t('evoucher.issuance.previewPrint') }}
        </v-btn>
        <v-btn
          small
          color="info darken-1"
          @click="onPreviewVoucherInfo"
        >{{ $t('evoucher.issuance.previewVoucher') }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="excelListEmailIssue.countErrorProcessing > 0 ? onShowModalConfirm() : onConfirm()"
        >{{ excelListEmailIssue.countErrorProcessing > 0 ? 'Tiếp tục' : $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onForceCreateEVoucherIssuance"
    />
    <previewPrintVoucher ref="previewPrintVoucher" />
    <e-voucher-preview-modal ref="voucherPreviewModal" />
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import {
  FOR_CONASI_TARGET,
  DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS,
  DEFAULT_MINIMUM_DAYS_FOR_EXPIRED_USING,
  NUM_OF_USES_PER_PERSON_VOUCHER_PROMOTION_DEFAULT
} from 'utils/constants'
import ToastType from 'enum/toastType'
import functionUtils, { uploadFileToMediaAgent } from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import stringUtils from 'utils/stringUtils'
import VoucherType from 'enum/voucherType'
import VoucherIssuanceStatusType from 'enum/voucherIssuanceStatusType'
import dateUtils from 'utils/dateUtils'
import EntityType from 'enum/entityType'
import ExpirationType from 'enum/expirationType'
import UploadExcel from 'Components/UploadExcel'
import moment from 'moment'
import PromotionType from 'enum/promotionType'
import CreateVoucherType from 'enum/createVoucherType'
import $ from 'jquery'
import PreviewPrintVoucher from 'Components/PreviewPrintVoucherModal'
import EVoucherPreviewModal from 'Views/EVoucher/EVoucherPreviewModal'
import VoucherModel from 'enum/voucherModel'
import VoucherStatusType from 'enum/voucherStatusType'
import ImagePicker from 'Components/ImagePicker.vue'
export default {
  components: {
    UploadExcel,
    PreviewPrintVoucher,
    EVoucherPreviewModal,
    ImagePicker
  },
  data () {
    return {
      valid: true,
      isShowModal: false,
      // expiredDate: null,
      workpiece: {
        name: null,
        code: null,
        quantity: null,
        status: VoucherIssuanceStatusType.ACTIVATED,
        statusList: stringUtils.DistributionStatus,
        type: VoucherType.VOUCHER,
        typeList: stringUtils.VoucherTypeList,
        valueTypeList: stringUtils.VoucherValueList,
        value: null,
        valueFormatter: null,
        expiredDateFormatted: null,
        menuExpiredDate: false,
        usageDuration: DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS, // For publish voucher
        minValue: null,
        minValueFormatter: null,
        maxValue: null,
        maxValueFormatter: null,
        description: null,
        conditionsOfUse: null,
        details: null,
        isTransferable: true,
        modelList: stringUtils.VoucherModelList,
        model: VoucherModel.NORMAL,
        numOfUsesPerPerson: NUM_OF_USES_PER_PERSON_VOUCHER_PROMOTION_DEFAULT,
        categories: [],
        categoriesList: [],
        isPersistentCode: false,
        isHidePrice: false,
        printTemplateId: null
      },
      VoucherType: VoucherType,
      VoucherModel: VoucherModel,
      isLoading: false,
      forConasi: FOR_CONASI_TARGET,
      usage_site: {
        list: [],
        ids: []
      },
      hasCreatePromotionVoucher: false,
      objectSettingKey: stringUtils.ObjectSettingKey,
      customerList: [],
      customerSelecteds: [],
      excelListEmailIssue: {
        results: [],
        headers: [],
        countErrorProcessing: 0,
        countSuccessedProcessing: 0
      },
      isForceCreate: false,
      confirmModalTitle: '',
      // Start issue expired date to customer
      expiredDate: moment().add(DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS, 'days').format('YYYY-MM-DD'),
      expiredDateFormatted: moment().add(DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS, 'days').format('DD/MM/YYYY'),
      menuExpiredDate: false, // End issue expired date
      minExpiredDate: moment().add(DEFAULT_MINIMUM_DAYS_FOR_EXPIRED_USING, 'days').format('YYYY-MM-DD'),
      // Start issue start date to customer
      startDate: moment().format('YYYY-MM-DD'),
      startDateFormatted: moment().format('DD/MM/YYYY'),
      minStartDate: moment().format('YYYY-MM-DD'),
      menuStartDate: false, // End issue end date to customer
      domainPath: stringUtils.DOMAIN_BASE_PATH_ADMIN,
      promotionType: PromotionType,
      promotionTypeList: stringUtils.PromotionTypeList,
      promotionTypeSelected: PromotionType.NOT_ALLOCATED,
      issuanceImage: null,
      imageFile: null,
      issuanceUrl: null,
      hashtagSelecteds: [],
      hashtagList: [],
      issuanceAlbumImages: [],
      option: {
        maxFileCount: 10
      },
      files: [],
      rawData: [],
      // For publish voucher right after created workpiece
      menuIssueStartDate: false,
      minIssueStartDate: moment().format('YYYY-MM-DD'),
      issueStartDateFormatted: moment().format('DD/MM/YYYY'),
      menuIssueExpiredDate: false,
      usedExpiredDateFormatted: null,
      usageDuration: DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS,
      voucherLengthCode: 5,
      createVoucherType: CreateVoucherType.LIST,
      isPrepay: true,
      selectedExpirationType: ExpirationType.DURATION,
      ExpirationType: ExpirationType,
      menuValidEndDate: false,
      usageEndDateFormatted: moment().endOf('year').format('DD/MM/YYYY'),
      usageEndDate: moment().endOf('year').format('YYYY-MM-DD'),
      selectSiteType: false,
      // entity site
      groupSiteList: [],
      groupSiteIds: [],
      expiredDateSelectItem: [
        { value: ExpirationType.DURATION, text: 'createVoucher.availableUsingDuration' },
        { value: ExpirationType.EXPIRY_DATE, text: 'createVoucher.expiredDateOfCustomers' }
      ]
    }
  },
  computed: {
    pickAllSites () {
      return this.usage_site.ids.length === this.usage_site.list.length
    },
    pickSomeSites () {
      return this.usage_site.ids.length > 0 && !this.pickAllSites
    },
    allSiteCheckIcon () {
      if (this.pickAllSites) return 'mdi-close-box'
      if (this.pickSomeSites) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    pickAllEntitySite () {
      return this.groupSiteIds.length === this.groupSiteList.length
    },
    pickSomeEntitySite () {
      return this.groupSiteIds.length > 0 && !this.pickAllEntitySite
    },
    allEntitySiteCheckIcon () {
      if (this.pickAllEntitySite) return 'mdi-close-box'
      if (this.pickSomeEntitySite) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    ...mapGetters([
      'GET_SNACK_BAR',
      'VOUCHER_ISSUANCE_DETAIL_DATA',
      'CHILDREN_ENTITY_DATA',
      'OBJECT_SETTING_DATA',
      'CUSTOMER_LIST_DATA',
      'GET_CURRENT_ACCOUNT',
      'CUSTOMER_GROUP_DATA'])
  },
  watch: {
    CHILDREN_ENTITY_DATA () {
      let data = this.CHILDREN_ENTITY_DATA
      this.usage_site.list = []
      for (let i = 0, size = data.length; i < size; i++) {
        let siteObj = {
          text: data[i].name,
          value: data[i].id,
          // for preview purpose
          name: data[i].name,
          email: data[i].email,
          phone: data[i].phone,
          province: data[i].province,
          district: data[i].district,
          ward: data[i].ward,
          street: data[i].street
        }
        this.usage_site.list.push(siteObj)
      }
      // Ensure all usgae sites are selected by default
      this.usage_site.ids = this.usage_site.list.slice()
    },
    OBJECT_SETTING_DATA () {
      let data = this.OBJECT_SETTING_DATA
      let hasCreatePromotionVoucherSettingData = data.filter((element) => {
        return element.key === this.objectSettingKey.HAS_PERM_CREATE_PROMOTION_VOUCHER
      })
      this.hasCreatePromotionVoucher = hasCreatePromotionVoucherSettingData.length > 0 ? hasCreatePromotionVoucherSettingData[0].value === 'True' : false
      // if (this.hasCreatePromotionVoucher) {
      //   this.workpiece.typeList.push({
      //     text: 'evoucher.issuance.promotion',
      //     value: VoucherType.PROMOTION
      //   })
      //   this.workpiece.valueTypeList.push({
      //     value: VoucherType.PROMOTION,
      //     text: 'evoucher.issuance.promotion'
      //   })
      // }
    },
    CUSTOMER_LIST_DATA () {
      let data = this.CUSTOMER_LIST_DATA.results
      this.customerList = []
      for (let i = 0, size = data.length; i < size; i++) {
        this.customerList.push({
          text: functionUtils.concatFullname(data[i].last_name, data[i].first_name) + ' - ' + data[i].email,
          value: data[i].id
        })
      }
    },
    /**
     * Issue start date to customer
     */
    startDate () {
      this.startDateFormatted = this.formatDate(this.startDate)
      if (this.startDate >= this.expiredDate) {
        this.expiredDate = moment(this.startDate, 'YYYY-MM-DD').add(DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS, 'days').format('YYYY-MM-DD')
      }
      this.minExpiredDate = moment(this.startDate, 'YYYY-MM-DD').add(DEFAULT_MINIMUM_DAYS_FOR_EXPIRED_USING, 'days').format('YYYY-MM-DD')
      this.minStartDate = this.startDate
    },
    /**
     * Issue expired date to customer
     */
    expiredDate (val) {
      this.expiredDateFormatted = this.formatDate(this.expiredDate)
    },
    GET_CURRENT_ACCOUNT () {
      this.handlePermVoucherPromotion()
    },
    CUSTOMER_GROUP_DATA () {
      let data = this.CUSTOMER_GROUP_DATA.results
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          text: data[i].name,
          value: data[i].id,
          count: data[i].number_member
        }
        this.hashtagList.push(obj)
      }
    },
    VOUCHER_ISSUANCE_DETAIL_DATA () {
      let data = this.VOUCHER_ISSUANCE_DETAIL_DATA
      this.workpiece.name = data.name
      // this.workpiece.code = data.code
      this.workpiece.quantity = data.quantity
      // this.workpiece.status = data.status
      this.workpiece.type = data.type
      this.formatValue(data.value)
      this.formatMinValue(data.min_value)
      this.formatMaxValue(data.max_value)
      this.workpiece.description = data.description
      this.workpiece.conditionsOfUse = data.conditions_of_use
      this.workpiece.details = data.details
      this.workpiece.model = data.model
      this.promotionTypeSelected = data.promotion_type
      this.workpiece.isTransferable = data.is_transferable
      this.workpiece.isPersistentCode = data.is_persistent_code
      this.workpiece.isHidePrice = data.is_hide_price
      this.issuanceUrl = data.image_url
      this.issuanceImage = data.image_url
      this.numOfUsesPerPerson = data.number_of_uses_per_person
      // this.workpiece.usageSites = data.usage_sites
      // this.usage_site.ids = data.usage_site_ids
      // this.customer.ids = data.customer_ids
    },
    /**
     * Valid date
     */
    usageEndDate (val) {
      this.usageEndDateFormatted = this.formatDate(this.usageEndDate)
    }
  },
  created () {
    this.handlePermVoucherPromotion()
    if (this.forConasi) {
      this.getTemplateConfigList()
    }
  },
  mounted () {},
  methods: {
    toggleAllSites () {
      this.$nextTick(() => {
        if (this.pickAllSites) {
          this.usage_site.ids = []
        } else {
          this.usage_site.ids = this.usage_site.list.slice()
        }
      })
    },
    toggleAllEntitySite () {
      // For group site
      this.$nextTick(() => {
        if (this.pickAllEntitySite) {
          this.groupSiteIds = []
        } else {
          this.groupSiteIds = this.groupSiteList.slice()
        }
      })
    },
    loaddropfile: function (e) {
      e.preventDefault()
      e.stopPropagation()
      alert('ok')
    },
    openinput: function () {
      document.getElementById('upload-product-album-img').click()
    },
    addImage: function (e) {
      const tmpFiles = e.target.files
      if (tmpFiles.length === 0) {
        return false
      }
      const file = tmpFiles[0]
      this.files.push(file)
      const self = this
      const reader = new FileReader()
      reader.onload = function (e) {
        self.rawData.push(e.target.result)
      }
      reader.readAsDataURL(file)
    },
    removeFile: function (index) {
      this.files.splice(index, 1)
      this.rawData.splice(index, 1)
      document.getElementById('upload-product-album-img').value = null
    },
    upload: function () {
      alert('Check console to see uploads')
    },
    /**
     * Upload album image
     */
    onUploadAlbumImage: function () {
      document.getElementById('fileUpload').click()
    },
    /**
     * Change upload album image
     */
    // onChangeUploadAlbumImage: function (e) {
    //   let files = e.target.files || e.dataTransfer.files
    //   if (!files.length) return
    //   this.imageUrlTest = URL.createObjectURL(files[0])
    //   this.getResultAlbumFile(files[0])
    // },
    // getResultAlbumFile: function (file) {
    //   let vm = this
    //   vm.issuanceAlbumImages.push({
    //     url: vm.imageUrlTest,
    //     file: file
    //   })
    // },
    /**
     * Handle perm voucher promotion
     */
    handlePermVoucherPromotion: function () {
      this.workpiece.typeList = this.workpiece.typeList.filter(element => {
        return element.value !== VoucherType.PROMOTION
      })
      this.workpiece.valueTypeList = this.workpiece.valueTypeList.filter(element => {
        return element.value !== VoucherType.PROMOTION
      })
      if (this.hasAction('create_voucher')) {
        this.workpiece.typeList.push({
          text: 'evoucher.issuance.promotion',
          value: VoucherType.PROMOTION
        })
        this.workpiece.valueTypeList.push({
          value: VoucherType.PROMOTION,
          text: 'evoucher.issuance.promotion'
        })
      }
    },
    /**
     * Check has action
     */
    hasAction: function (metaAction) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaAction)
    },
    /**
     * Preview print voucher
     */
    onPreviewPrintVoucher: function () {
      let voucherInfo = {
        supplierId: sessionStorage.getItem('entityId'),
        supplierName: sessionStorage.getItem('entityName'),
        supplierHotline: sessionStorage.getItem('entityPhone'),
        supplierWebsite: sessionStorage.getItem('entityWebsite'),
        name: this.workpiece.name,
        price: Number(this.workpiece.value),
        type: this.workpiece.type,
        isPersistentCode: this.workpiece.isPersistentCode,
        isHidePrice: this.workpiece.isHidePrice,
        expiredDate: this.expiredDateFormatted,
        description: this.workpiece.description,
        conditionsOfUse: this.workpiece.conditionsOfUse,
        details: this.workpiece.details,
        logoSupplier: sessionStorage.getItem('entityImage'),
        logoAgent: null
      }
      this.$refs.previewPrintVoucher.onShowModal(voucherInfo, this.workpiece.printTemplateId)
    },
    onPreviewVoucherInfo: function () {
      let siteIds = functionUtils.convertToArrayId(this.usage_site.ids)
      let usageSites = this.usage_site.list.filter(element => {
        return siteIds.includes(element.value)
      })
      let voucherInfo = {
        id: null,
        serial: functionUtils.randomString(9),
        type: this.workpiece.type,
        value: this.workpiece.value,
        name: this.workpiece.name,
        description: this.workpiece.description,
        conditionsOfUse: this.workpiece.conditionsOfUse,
        details: this.workpiece.details,
        imageUrl: this.issuanceImage,
        supplierName: sessionStorage.getItem('entityName'),
        status: VoucherStatusType.INACTIVE,
        expiredDate: this.expiredDateFormatted,
        // usedPrice: null,
        // usedDiscountPrice: null,
        workpieceCode: null,
        usageSites: usageSites,
        discountType: this.workpiece.type
      }
      this.$refs.voucherPreviewModal.onShowModal(null, voucherInfo)
    },
    /**
     * Select issuance img
     */
    onSelectIssuanceImg: function (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.getResultFile(files[0])
    },
    /**
     * Remove issuance img
     */
    onClickRemoveIssuanceImg: function () {
      this.imageFile = null
      this.issuanceImage = null
      this.issuanceUrl = null
    },
    /* Utility function to convert a canvas to a BLOB */
    dataURLToBlob: function (dataURL) {
      var parts = ''
      var contentType = ''
      var raw = ''
      var BASE64_MARKER = ';base64,'
      // eslint-disable-next-line eqeqeq
      if (dataURL.indexOf(BASE64_MARKER) == -1) {
        parts = dataURL.split(',')
        contentType = parts[0].split(':')[1]
        raw = parts[1]
        return new Blob([raw], { type: contentType })
      }
      parts = dataURL.split(BASE64_MARKER)
      contentType = parts[0].split(':')[1]
      raw = window.atob(parts[1])
      var rawLength = raw.length
      var uInt8Array = new Uint8Array(rawLength)
      for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }
      return new Blob([uInt8Array], { type: contentType })
    },
    /**
     * Convert data url to file
     */
    dataURLtoFile: function (dataurl, filename) {
      // eslint-disable-next-line one-var
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    /* End Utility function to convert a canvas to a BLOB */
    getResultFile: function (file) {
      // Ensure it's an image
      var vm = this
      if (file.type.match(/image.*/)) {
        // Load the image
        var reader = new FileReader()
        reader.onload = function (readerEvent) {
          var image = new Image()
          image.onload = function (imageEvent) {
            // Resize the image
            var canvas = document.createElement('canvas')
            let width = 300
            let height = 200
            canvas.width = width
            canvas.height = height
            canvas.getContext('2d').drawImage(image, 0, 0, width, height)
            var dataUrl = canvas.toDataURL('image/jpeg')
            var resizedImage = vm.dataURLToBlob(dataUrl)
            $.event.trigger({
              type: 'imageResized',
              blob: resizedImage,
              url: dataUrl,
              name: file.name
            })
          }
          image.src = readerEvent.target.result
        }
        reader.readAsDataURL(file)
      }
      $(document).on('imageResized', function (event) {
        if (event.blob && event.url) {
          vm.imageFile = vm.dataURLtoFile(event.url, event.name)
          vm.issuanceImage = event.url
        }
      })
    },
    /**
     * Force create e voucher issuance
     */
    onForceCreateEVoucherIssuance: function () {
      this.isForceCreate = true
      this.onConfirm()
    },
    /**
     * Show modal confirm
     */
    onShowModalConfirm: function () {
      this.confirmModalTitle = 'Tồn tại email khách hàng không hợp lệ, bạn có muốn tiếp tục?'
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Handle success upload file excel
     */
    handleSuccess: function ({ results, header }) {
      this.excelListEmailIssue.results = results
      this.excelListEmailIssue.headers = header
      this.excelListEmailIssue.countErrorProcessing = 0
      this.excelListEmailIssue.countSuccessedProcessing = 0
      this.isForceCreate = false
    },
    /**
     * Before upload file excel
     */
    beforeUpload: function (file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      return false
    },
    /**
     * Required value
     */
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Greater than zero
     */
    ruleMustGreaterThanZero (value) {
      return validationRules.ruleMustGreaterThan(value, 0)
    },
    // Not greater one hundred
    ruleMaxPercentValue (value) {
      if (this.workpiece.type === VoucherType.VOUCHER || this.workpiece.type === VoucherType.PROMOTION) {
        return true
      }
      return validationRules.ruleMustLessThan(value, 100)
    },
    /**
     * Alway greater min value
     */
    ruleMustGreaterThanMinValue (value) {
      return validationRules.ruleMustGreaterThan(value, this.workpiece.minValue)
    },
    /**
     * E Voucher issuance type change
     */
    voucherIssuanceTypeChange: function () {
      this.workpiece.value = null
      this.workpiece.valueFormatter = null
    },
    onVoucherPromotionTypeChange: function () {
      if (this.workpiece.type === VoucherType.PROMOTION && this.promotionTypeSelected === PromotionType.ALLOCATED) {
        let numOfUsesPerPerson = Number(this.workpiece.numOfUsesPerPerson)
        let quantity = 0
        for (let i = 0, size = this.hashtagList.length; i < size; i++) {
          if (this.hashtagSelecteds.includes(this.hashtagList[i].value)) {
            quantity = quantity + numOfUsesPerPerson * this.hashtagList[i].count
          }
        }
        this.workpiece.quantity = quantity
      }
    },
    /**
     * Format value
     */
    formatValue: function (value) {
      if (value != null) {
        value = functionUtils.formatInteger(value && value.toString())
        if (this.workpiece.type === VoucherType.VOUCHER || this.workpiece.type === VoucherType.PROMOTION) {
          this.workpiece.valueFormatter = functionUtils.convertFormatNumberToMoney(value)
        } else {
          this.workpiece.valueFormatter = value
        }
        this.workpiece.value = value
      }
    },
    /**
     * Format min value
     */
    formatMinValue: function (value) {
      if (value != null) {
        value = functionUtils.formatInteger(value && value.toString())
        this.workpiece.minValueFormatter = functionUtils.convertFormatNumberToMoney(value)
        this.workpiece.minValue = value
      }
    },
    /**
     * Format min value
     */
    formatMaxValue: function (value) {
      if (value != null) {
        value = functionUtils.formatInteger(value && value.toString())
        this.workpiece.maxValueFormatter = functionUtils.convertFormatNumberToMoney(value)
        this.workpiece.maxValue = value
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.isShowModal = false
      this.expiredDate = null
      var vm = this
      Object.keys(vm.workpiece).forEach(function (key, value) {
        if (key === 'usageDuration') {
          vm.workpiece[key] = DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS
        } else if (key === 'type') {
          vm.workpiece[key] = VoucherType.VOUCHER
        } else if (key === 'status') {
          vm.workpiece[key] = VoucherIssuanceStatusType.ACTIVATED
        } else if (key === 'menuExpiredDate') {
          vm.workpiece[key] = false
        } else if (key !== 'typeList' && key !== 'valueTypeList' && key !== 'statusList' && key !== 'modelList' && key !== 'categoriesList') {
          vm.workpiece[key] = null
        }
      })
      this.excelListEmailIssue.headers = []
      this.excelListEmailIssue.results = []
      this.excelListEmailIssue.countErrorProcessing = 0
      this.excelListEmailIssue.countSuccessedProcessing = 0
      this.isForceCreate = false
      // For publish voucher after created issuance
      this.selectedExpirationType = 1
      this.usageEndDateFormatted = moment().endOf('year').format('DD/MM/YYYY')
      this.usageEndDate = moment().endOf('year').format('YYYY-MM-DD')
    },
    /**
     * Show modal
     */
    onShowModal: function (cloneIssuanceId) {
      this.usageDuration = DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS
      this.getChildrenEntity()
      this.getEntitySiteList() // group site
      this.isShowModal = true
      let entityId = sessionStorage.getItem('entityId')
      let filter = {
        params: {
          objectId: entityId,
          objectType: 'Entity',
          hasCreatePromotionVoucher: stringUtils.ObjectSettingKey.HAS_PERM_CREATE_PROMOTION_VOUCHER
        }
      }
      this.GET_OBJECT_SETTING(filter)
      // Get customer list
      this.GET_CUSTOMER_LIST()
      // Get hashtag list
      this.GET_CUSTOMER_GROUP_LIST()
      // Get categories
      this.GET_VOUCHER_PRODUCT_CATEGORIES().then(
        function (res) {
          let data = res.data
          for (let i = 0, size = data.length; i < size; i++) {
            this.workpiece.categoriesList.push({ text: data[i].name, value: data[i].id })
          }
        }.bind(this)
      )
      if (cloneIssuanceId != null) {
        this.GET_VOUCHER_ISSUANCE_DETAIL({ id: cloneIssuanceId })
      }
    },
    /**
     * Get children entity
     */
    getChildrenEntity: function () {
      let filter = {
        params: {
          type: EntityType.SITE
        }
      }
      this.GET_CHILDREN_ENTITY(filter)
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.setDefaultData()
    },
    /**
     * Upload issuance image
     */
    uploadIssuanceImage: async function () {
      this.issuanceUrl = await uploadFileToMediaAgent(this.imageFile, 'eVoucher/Issuance')
    },
    /**
     * Upload issuance image
     */
    uploadIssuanceAlbumImage: async function () {
      this.issuanceAlbumImages = []
      for (let i = 0, size = this.files.length; i < size; i++) {
        let imageUrl = await uploadFileToMediaAgent(this.files[i], 'eVoucher/Product/AlbumImages')
        if (imageUrl != null) {
          this.issuanceAlbumImages.push(imageUrl)
        }
      }
    },
    /**
     * Validate
     */
    validate: async function () {
      this.usage_site.ids = functionUtils.convertToArrayId(this.usage_site.ids)
      if (this.$refs.form.validate()) {
        let expiredDate = dateUtils.formatSqlDate(this.workpiece.expiredDateFormatted, null)
        let startDate = dateUtils.formatSqlDate(this.startDateFormatted, null)
        let endDate = dateUtils.formatSqlDate(this.expiredDateFormatted, null)
        let sitesEntityData = this.getSitesInfo()
        // create voucher data
        let createFilter = {}
        if (!this.forConasi) {
          let usedExpiredDate = dateUtils.formatSqlDate(this.usedExpiredDateFormatted, null)
          let usageEndDate = null
          if (this.selectedExpirationType === ExpirationType.EXPIRY_DATE) {
            if (functionUtils.isEmptyString(this.usageEndDateFormatted)) {
              this.ON_SHOW_TOAST({
                'message': this.$t('createVoucher.availableUsingDuration'),
                'styleType': ToastType.ERROR
              })
              return
            } else {
              usageEndDate = dateUtils.formatSqlDate(this.usageEndDateFormatted, null)
            }
          }
          createFilter = {
            // create voucher data
            voucherType: this.createVoucherType,
            // voucherQuantity: this.quantity, là số lượng từ tạo phôi
            lengthCode: this.voucherLengthCode,
            startChar: functionUtils.getFirstCharacterInString(this.workpiece.name),
            endChar: moment().format('MMYYYY'),
            workpieceId: null,
            voucherList: [{ serial: null }],
            // entityId: entityId,
            distributeEndDate: null,
            deliveredDate: null,
            usedExpiredDate: usedExpiredDate,
            isPrepay: this.isPrepay,
            usageDuration: this.selectedExpirationType === ExpirationType.DURATION ? this.usageDuration : null,
            usageEndDate: usageEndDate,
            groupSiteIds: functionUtils.convertToArrayId(sitesEntityData.groupSiteIds)
          }
        }
        let filter = {
          name: this.workpiece.name,
          code: this.workpiece.code,
          quantity: this.workpiece.quantity,
          status: this.workpiece.status,
          type: this.workpiece.type,
          value: this.workpiece.value,
          description: this.workpiece.description,
          expiredDate: expiredDate,
          minValue: this.workpiece.minValue,
          maxValue: this.workpiece.maxValue,
          siteIds: sitesEntityData.siteIds,
          details: this.workpiece.details,
          customerIds: this.customerSelecteds,
          headers: this.excelListEmailIssue.headers,
          dataImport: this.excelListEmailIssue.results,
          isForceCreate: this.isForceCreate,
          startDate: startDate,
          endDate: endDate,
          promotionType: this.promotionTypeSelected,
          issuanceUrl: this.issuanceUrl,
          conditionsOfUse: this.workpiece.conditionsOfUse,
          hashtagIds: this.hashtagSelecteds,
          isTransferable: this.workpiece.isTransferable,
          model: this.workpiece.model,
          albumImages: this.issuanceAlbumImages,
          categories: this.workpiece.categories,
          isPersistentCode: this.workpiece.isPersistentCode,
          isHidePrice: this.workpiece.isHidePrice,
          numOfUsesPerPerson: this.workpiece.numOfUsesPerPerson,
          printTemplateId: this.workpiece.printTemplateId,
          ...createFilter
        }
        if (this.imageFile != null) {
          await this.uploadIssuanceImage()
          filter['issuanceUrl'] = this.issuanceUrl
        }
        await this.uploadIssuanceAlbumImage()
        filter['albumImages'] = this.issuanceAlbumImages
        this.isLoading = true
        if (this.forConasi) {
          this.ADD_VOUCHER_ISSUANCE(filter).then(
            function (res) {
              let data = res.data
              this.excelListEmailIssue.countErrorProcessing = data.error_processing_count
              this.excelListEmailIssue.countSuccessedProcessing = data.successed_processing_count
              this.excelListEmailIssue.headers = data.headers
              this.excelListEmailIssue.results = data.response_list
              this.handleCreateIssuanceSuccess()
            }.bind(this)
          ).catch(
            function (error) {
              this.handleCreateIssuanceError(error)
            }.bind(this)
          )
        } else {
          this.CREATE_ISSUANCE_AND_VOUCHER(filter).then(
            function (res) {
              this.handleCreateIssuanceSuccess()
            }.bind(this)
          ).catch(
            function (error) {
              this.handleCreateIssuanceError(error)
            }.bind(this)
          )
        }
      }
    },
    handleCreateIssuanceSuccess: function () {
      if (this.excelListEmailIssue.countErrorProcessing === 0 || this.isForceCreate || this.workpiece.type !== this.VoucherType.PROMOTION) {
        this.isForceCreate = false
        this.ON_SHOW_TOAST({
          message: this.$t('common.success'),
          styleType: ToastType.SUCCESS
        })
        this.setDefaultData()
        this.$emit('createEVoucherSuccess')
      }
      if (this.forConasi) {
        if (this.excelListEmailIssue.countErrorProcessing !== 0) {
          this.onShowModalConfirm()
        }
      }
      this.isLoading = false
    },
    handleCreateIssuanceError: function (error) {
      this.isLoading = false
      if (!functionUtils.isNull(error.response.data.errorText)) {
        let errorText = error.response.data.errorText
        this.ON_SHOW_TOAST({
          message: this.$t(`${errorText}`),
          styleType: ToastType.ERROR
        })
      } else {
        this.ON_SHOW_TOAST({
          message: this.$t('login.somethingWentWrong'),
          styleType: ToastType.ERROR
        })
      }
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      this.validate()
    },
    getTemplateConfigList: function () {
      this.isLoading = true
      this.printTemplateList = [{ value: null, text: '---' }]
      this.GET_PRINT_TEMPLATE_CONFIG_LIST().then(
        function (res) {
          let data = res.data
          data.forEach(e => {
            let item = {
              value: e.id,
              text: !functionUtils.isEmptyString(e.name) ? e.name : e.id
            }
            this.printTemplateList.push(item)
          })
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }
      )
    },
    getEntitySiteList: function () {
      this.GET_SITE_GROUP_LIST().then(
        function (res) {
          let results = res.data
          this.groupSiteList = []
          results.forEach((e) => {
            let item = {
              value: e.id,
              text: e.name
            }
            this.groupSiteList.push(item)
          })
        }.bind(this)
      )
    },
    getSitesInfo: function () {
      if (this.selectSiteType) {
        return {
          'siteIds': [],
          'groupSiteIds': this.groupSiteIds
        }
      } else {
        return {
          'siteIds': this.usage_site.ids,
          'groupSiteIds': []
        }
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_VOUCHER_ISSUANCE_DETAIL',
      'ADD_VOUCHER_ISSUANCE',
      'CREATE_ISSUANCE_AND_VOUCHER',
      'GET_CHILDREN_ENTITY',
      'GET_OBJECT_SETTING',
      'GET_CUSTOMER_LIST',
      'GET_CUSTOMER_GROUP_LIST',
      'GET_VOUCHER_PRODUCT_CATEGORIES',
      'GET_SITE_GROUP_LIST',
      'GET_PRINT_TEMPLATE_CONFIG_LIST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
.tableFixHead          { overflow-y: auto; max-height: 300px; }
.tableFixHead thead th { position: sticky; top: 0; }

/* Just common table stuff. Really. */
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px;}
th     { background:#eee; }
/deep/.v-image-picker .theme--light.v-card {
  background-color: #e5e5e5;
}
input[type="file"] {
  display: none;
}
.image-input {
  padding: 3px;
}

.image-preview {
  width: 96px;
  height: 96px;
  cursor: pointer;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.dropzone {
  width: 96px;
  height: 96px;
}
.dropzone {
  border: 1px dashed green;
  border-radius: 7%;
}
.remove-file {
  position: absolute;
  margin-top: 5px;
  margin-left: 0px;
  color: rgba(0,0,0,0.5);
  padding: 1px 6px;
  background-color: rgba(0,0,0,0.3);

}
.remove-file:hover {
  color: #555;
  background-color: #f4f5f7;
}
.customer-check /deep/.v-input__slot {
  margin: 0;
}
.customer-check /deep/.v-label {
  font-size: 14px;
  font-weight: normal;
}
.customer-check /deep/.v-messages {
  display: none;
}
</style>
