<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('issueVoucherRequest.sendRequestToParenTitle')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-layout wrap>
              <v-flex
                xs12
                sm6
                md6>
                <v-text-field
                  :rules="[ ruleRequiredValue ]"
                  v-model="name"
                  :label="$t('issueVoucherRequest.title')"
                  :name="$t('issueVoucherRequest.title')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6>
                <v-autocomplete
                  :rules="[ruleRequiredValue]"
                  v-model="workpiece.id"
                  :items="workpiece.data"
                  :clearable="true"
                  :label="$t('evoucher.issuance.workpiece')"
                  :disabled="sendFromIssuance"
                  class="required"
                  @change="voucherIssuanceChange"
                >
                  <template v-slot:item="data">
                    <span>{{ data.item.text }} <span class="grey--text font-small">[{{ data.item.code }}]</span></span>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6>
                <v-autocomplete
                  :rules="[ ruleRequiredValue ]"
                  v-model="entitySelected.id"
                  :items="entities"
                  :clearable="true"
                  :label="$t('issueVoucherRequest.parent')"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6>
                <v-text-field
                  :rules="[ ruleRequiredValue, ruleMustGreaterThanZero ]"
                  v-model="quantity"
                  :label="$t('evoucher.quantity')"
                  :name="$t('evoucher.quantity')"
                  type="number"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm12
                md12>
                <v-textarea
                  v-model="description"
                  :label="$t('common.note')"
                  :name="$t('common.note')"
                  type="text"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import validationRules from 'utils/validationRules'
export default {
  data: () => ({
    valid: false,
    isShowModal: false,
    isLoading: false,
    entities: [],
    entitySelected: {
      id: null,
      type: null
    },
    description: null,
    quantity: null,
    name: null,
    workpiece: {
      data: [],
      id: null
    },
    sendFromIssuance: false,
    parentEntities: []
  }),
  computed: {
    ...mapGetters([
      'PARENT_ENTITY_DATA',
      'GET_SNACK_BAR',
      'ISSUANCE_OF_VOUCHER_ALREADY_EXIST_DATA'
    ])
  },
  watch: {
    PARENT_ENTITY_DATA () {
      let data = this.PARENT_ENTITY_DATA
      this.parentEntities = data
      this.handleEntities(data)
    },
    ISSUANCE_OF_VOUCHER_ALREADY_EXIST_DATA () {
      let data = this.ISSUANCE_OF_VOUCHER_ALREADY_EXIST_DATA
      this.workpiece.data = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          value: data[i].id,
          text: data[i].name,
          code: data[i].code,
          owner: data[i].owner
        }
        this.workpiece.data.push(obj)
      }
    }
  },
  methods: {
    /**
     * Get parent entity
     */
    getParentEntity: function () {
      let parentFilter = {
        params: {
          workpieceId: this.workpiece.id
        }
      }
      this.GET_PARENT_ENTITY(parentFilter)
    },
    /**
     * Voucher issuance change
     */
    voucherIssuanceChange: function () {
      if (this.workpiece.id) {
        this.getParentEntity()
      }
    },
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleMustGreaterThanZero (value) {
      return validationRules.ruleMustGreaterThan(value, 0)
    },
    /**
     * Handle data entities
     */
    handleEntities: function (data) {
      this.entities = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          value: data[i].id,
          text: data[i].name
        }
        this.entities.push(obj)
      }
    },
    /**
     * Set default
     */
    setDefault: function () {
      this.name = null
      this.quantity = null
      this.description = null
      this.entitySelected.id = null
      this.entitySelected.type = null
      this.entities = []
      this.workpiece.data = []
    },
    /**
     * Confirm modal
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        let filter = {
          name: this.name,
          toEntityId: this.entitySelected.id,
          description: this.description,
          quantity: this.quantity,
          workpieceId: this.workpiece.id
        }
        this.isLoading = true
        this.SEND_VOUCHER_REQUEST_TO_PARENT(filter).then(
          function () {
            this.isLoading = false
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.setDefault()
            this.onCloseModal()
          }.bind(this)
        )
      }
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
      this.setDefault()
    },
    /**
     * Show modal
     */
    onShowModal: function (workpieceId, sendFromIssuance) {
      this.isShowModal = true
      this.GET_ISSUANCE_OF_VOUCHER_ALREADY_EXIST_LIST()
      this.workpiece.id = workpieceId
      if (this.workpiece.id) {
        this.getParentEntity()
      }
      this.sendFromIssuance = sendFromIssuance
    },
    ...mapActions([
      'GET_PARENT_ENTITY',
      'SEND_VOUCHER_REQUEST_TO_PARENT',
      'ON_SHOW_TOAST',
      'GET_ISSUANCE_OF_VOUCHER_ALREADY_EXIST_LIST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
